.react-calendar {
  width: 100%;
  border: none;
  .react-calendar__viewContainer {
    .react-calendar__month-view__days {
      .react-calendar__month-view__days__day {
        color: #363636
      }
      :disabled {
        color: #CCC
      }
    }
    .react-calendar__tile--now {
      background-color: #FFF;
    }
    .react-calendar__tile--active {
      background-color: #CCC !important;
    }
  }
}
